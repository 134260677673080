<template>
    <loading-card :loading="!loaded">
        <div class="card-header">{{ $t('crud.products._show') }}</div>
        <div class="card-body">
            <products-form-component @submit="updateFromRoute" :record="record"></products-form-component>
        </div>
    </loading-card>
</template>

<script>;
    import {functions, config} from '../crud/mixin_config';
    import ProductsFormComponent from './Form';

    export default {
        name: 'products-show-component',
        mixins: [functions],
        components: {ProductsFormComponent},
        data() {
            return {
                crud: config('products')
            };
        }
    }
</script>
